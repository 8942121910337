.ui-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .ui-icon {
        // padding-left:5px;
        padding-right: 10px;
    }
}

.ui-datepicker {
    background-color: $color-bg;
    table {
        margin-top: 0px !important;
    }
}
