.footer{
  // min-height: 21vw;
  background-color:#1d3229f1;
  padding-top: 3vw;
  padding-bottom: 3vw;
  position: relative;
  margin-top: 30vw;
  @include mq($until: sm) {
  margin-top: 34vw;
  padding-top: 0;
  }


  &__menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3vw;

    @include mq($until: lg) {
       padding-bottom: 0;
    }    
    @include mq($until: xs) {
       flex-direction: column;
    }
        
  }
  &__header{
    display: flex;
    width: auto;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: md) {
     display: none;
    }
    @include mq($until: sm) {
     display: none;
   } 
   .footer-menu{
     display: flex;
     a{
       color: #fff;

     }
     ::before{
      display: none;
     }
   }

  }
  &__logo{
    width: 160px;
    @include mq($until: xl) {
      max-width: 24vw;
    }
    @include mq($until: sm) {
      padding: 0 0 34px 0;
      max-width: 34vw;
    }
    @include mq($until: xs) {
      max-width: 180px;
    }
  }
  &__rezerwation{
    color: #fff;
    padding: 8px 34px;
    display: inline-block;
    border: solid 1px #fff;
    transition: all .3 ease-in-out;
    &:hover{
     opacity: .4;
    }
  }
  // .header__menuList{
  //   @include mq($until: md) {
  //     flex-direction: row;
  //     width: 50vw;
  //     justify-content: space-between;
  //     align-items: center;
  //   }
  //   @include mq($until: sm) {
  //     width: 95vw;
  //     justify-content: space-around;
  //     background-color:rgba(128, 128, 128, 0.205);
  //   }
  //   .header__menuItem{
      
  //     a{
  //       color: #fff;
  //     }
  //     @include mq($until: md) {
  //       margin: 0;
  //     }
  //   }  
  // }
  &__nav{
    @include mq($until: md) {
      flex-direction: row;
     }
    a{
      color: #fff;
      font-size: rem(21px);
      &:hover{
        color: $color-extra;
      }
    }
  }

  &__contact-data{
    display: flex;
    justify-content: space-between;
    // align-items:baseline;

    @include mq($until: xs){
      align-items:center;
    }

    @include mq($until: xs){
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    span, p, a, {
      color: #fff;
      margin-bottom: 8px;
    }

  }

  &__arangement{
    display: flex;
    align-items: center;
    @include mq($until: sm) {
      flex-direction: column;
    }
    &--small{
      display: flex;
    }
  }

  &__tittle{
    font-size: rem(21px);
    color: #fff;
    border-bottom: 2px solid #fff;
    @include mq($until: xl) {
      font-size: rem(18px);
    }
  }

  &__kontakt{
    @include mq($until: md) {
    //  margin-bottom: 34px;
    }
    @include mq($until: sm) {
    //  margin-bottom: 21px;
    }
  }

  &__biuro{

    //  @include mq($until: sm) {
    //   margin-bottom: 21px;
    //   margin-right: 0;
    // }
  }

  &__data-box{
    display: flex;
    align-items: flex-start;
    margin-top: 34px;
    justify-content: space-around;
    min-width: 100px;
    @include mq($until: xl) {
      margin-top: 21px;
    }
    @include mq($until: lg) {

      min-width: unset;
    }
    @include mq($until: md) {
      // flex-direction: row;
    }

    svg{
      fill: #fff;
      // opacity: 0.4;
      transition: opacity 0.4s ease-out;
      @include mq($until: lg) {
        width: 3.5vw;
      } 
      @include mq($until: sm) {
        width: 34px;
      } 
      @include mq($until: xs) {
        width: 40px;
      } 
      &:hover{
        opacity: 1;
      }
    }
    &--rwd{
      @include mq($until: lg) {
        width: 0;
        display: flex;
        margin-left: 18px;
      }
      @include mq($until: sm) {
        margin-left: 13px;
        a{
          margin-bottom: 0;
        }
      }
      @include mq($until: xs) {
        margin-left: 0px;
        flex-direction: row;
        width: 160px;
      }
    }
  }
  &__phone{


  }
  &__icon{
    color: #fff;
    padding: 0 13px;
    opacity: 1;
    transition: all .4s ease-in-out;
    svg{
      fill: #fff;
      width: 24px;
      margin-right: 8px;
    }
    &:hover {
      opacity: .3;
    }
    @include mq($until: xs) {
      padding: 0;
      padding-bottom: 21px;
    }
  }

  &__numbers{
      margin-bottom: 50px;
      @include mq($until: xl) {
        font-size: rem(16px);
      }
      @include mq($until:md) {
        font-size: rem(19px);
      }
      @include mq($until:sm) {
        font-size: rem(16px);
      }
  }

  &__address{
    @include mq($until: xl) {
      p{
        font-size: rem(16px);
      }
    }
    @include mq($until:md) {
      p{
        font-size: rem(16px);
      }
    }
    @include mq($until:sm) {
      p{
        font-size: rem(16px);
      }
    }
    @include mq($until:xs) {
      margin-right: 0px;
    }
  }

  &__email{
    display: flex;
    min-width: fit-content;
    margin-right: 8px;
    a,span{
      font-size: rem(18px);
      @include mq($until: xl) {
        font-size: rem(16px);
      }
      @include mq($until: md) {
          font-size: rem(18px);
      }
      @include mq($until: sm) {
          font-size: rem(16px);
      }
    }
  }
  &__open{
    min-width: fit-content;
      p{
        font-size: rem(18px);
        @include mq($until: xl) {
          font-size: rem(16px);
        }
        @include mq($until: md) {
            font-size: rem(18px);
        }
        @include mq($until: sm) {
            font-size: rem(14px);
        }
      }
    @include mq($until: xs) {
      margin-right: 0;
    }
  }
  &__ico{
    padding-right: 13px;
    margin-bottom: 5px;
  }


  &__bg-foto{
    background-image: url(../../images/footer.jpg);
    width: 100%;
    height: 48vw;
    background-position: center top;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include mq($until: sm) {
      height: inherit;
      top: -30vw;
    }
    @include mq($until: xs) {
      height: inherit;
      top: -50vw;
    }
  }
  &__slogan{
    text-align: center;
    caption-side: 5v;
    padding-top: 13vw;
    h2{
      color: #1d3229;
      font-family: 'Clicker Script', cursive;
      font-size: rem(40px);
      text-shadow: 4px 4px 22px rgb(13, 12, 82);
    }

  }
  
}


.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    position: static;
    &__copyright{
      padding:8px;
    }
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
        max-width: 100px;
        @include mq($until: sm) {
          max-width: 75px;
          margin-left: 8px;
        }
      }
      @include mq($until: md) {
        background-color:  #f7f6f5;
      }
    }
    @include mq($until: md) {
      flex-wrap: wrap;
      a{
        padding:8px;
        justify-content: center;
        width: 100%;
      }
    }
    @include mq($until: xs) {
      flex-direction: column;
    }
}

#mapa-footer{
  height: 34vw;
  @include mq($until: md) {
    height: 100vw;
  }
}