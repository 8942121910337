.img-container {
    .img-background-left {
        position: absolute;
        z-index: -1;
        background-color: #eff7fa;
        margin-top: -5%;
        margin-left: 5%;
    }
    .img-background-right {
        position: absolute;
        z-index: -1;
        background-color: #eff7fa;
        margin-top: -5%;
        margin-left: -5%;
    }
    .fr-fic {
        width: 100% !important;
    }
}
.title-right {
    float: right;
    margin-top: 0px;
}

.title-container {
    background-color: #eff7fa;
    width: 50%;
    height: 130px;
    position: absolute;
    z-index: -1;
    margin-left: -50px;
    margin-top: -50px;
    @media (max-width: 560px) {
        height: 100px;
        width: 70%;
    }
    h1 {
        margin: 0px;
        padding: 0px;
    }
}
.rent-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reversed {
    @media (max-width: 560px) {
        display: flex;
        flex-direction: column-reverse;
    }
}
