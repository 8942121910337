.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 5;
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color 0.3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo {
    position: relative;
    z-index: 2;
    width: 20vw;
    max-width: 500px;
    padding: 0 1vw;
    min-width: 300px;
  }
  &__above {
    display: flex;
    align-items: baseline;
  }
  &__menu {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    div:last-of-type {
      display: flex;
      align-items: center;
      @include mq($until: md) {
        flex-direction: column;
      }
    }
  }

  &__buttons {
    margin-bottom: 25px;
    & .button_tr {
      border: 1px solid #ffffff !important;
      color: white !important;
      text-shadow: 1px 2px 5px black;
      box-shadow: 1px 2px 5px black;
    }
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    background-color: #1d3229d9;
    padding: 10px;
    &--fullscreen {
      flex-direction: column;
    }
    @include mq($until: md) {
      justify-content: center;
      height: 100%;
      margin: unset;
    }
  }

  &__menuItem {
    margin-top: 0;
    margin-left: 10px;
    padding: 0;
    &--fullscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #ffffff;
    text-shadow: 1px 2px 5px black;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active,
    &--active,
    &--open,
    &:hover {
      color: #A6A6A6;
    }
  }

  @include mq($until: md) {
    &__logo {
      min-width: 200px;
    }

    &__buttons {
      margin-bottom: 0;
    }
  }

  @include mq($until: xs) {
    &__buttons {
      &:first-child {
        display: none;
      }
    }

    &__logo {
      min-width: 150px;
    }
  }
}

div#flags {
  a {
    margin: 0 0 0 25px;
  }

  img {
    width: 40px;
    opacity: 0.5;
    transition: all 0.3s;
    &:hover {
      opacity: 1;
    }
  }

  .active-lang {
    img {
      opacity: 1;
      box-shadow: 0 0 8px #00000050;
    }
  }

  @include mq($until: md) {
    position: absolute;
    right: 43px;
    top: 125%;

    a {
      display: inline-block;
      margin: 0 7px 13px 7px;
    }

    img {
      width: 30px;
    }
  }

  @include mq($until: sm) {
    right: 30px;
  }

  @include mq($until: xs) {
    right: 10px;
  }
}
