.icons-set {
  display: grid;
  text-align: center;
  gap: 1vw;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    max-width: 200px;
    justify-self: center;

    img {
      height: 110px;
      margin-bottom: 30px;
    }

    &::before {
      content: none;
    }

  }
  
  &__item-text {
      font-weight: 600;
      font-family: $font-secondary;
      font-size: rem(35px);

  }

  @include mq($until: xxl) {
    &__item {
  
      img {
        height: 90px;
      }
    }
  }

  @include mq($until: xl) {
    &__item {
  
      img {
        height: 60px;
      }
    }
  }

  @include mq($until: lg) {
    &__item {
  
      img {
        height: 45px;
      }
    }
  }

  @include mq($until: md) {

    &__item {
      margin-bottom: 50px;

      img {
        height: 10vw;
      }
    }

    &__item-text {
      font-size: rem(35px);
    }

  }

  @include mq($until: sm) {

    &__item-text {
      font-size: rem(25px);
    }

  }

}

.icons-grid {

  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }
  
    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  
    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }
  
    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }
  
    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
