.top {
    width: 100%;
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h2 {
        color:white;
    }
    @include mq($until: xs) {
        height: 70vh;
    }
}

.search {
    height: 15vh;
    display: flex;

    &__widget {
        height: 100%;
        width: 50%;
        background-color: $color-bg;

        form {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            & > div {
                max-width: 290px;
            }

            button,
            input {
                margin: 0 15px;
                padding: 15px 0;
                font-size: rem(22px);
            }

            input {
                background-color: transparent;
                border: none;
                border-bottom: 2px solid $color-main;
                transition: all 0.5s;
                width: 90%;

                &:focus-visible {
                    border: none;
                    outline: none;
                    border-bottom: 2px solid $color-main;
                }
            }

            button {
                background-color: $color-main;
                color: white;
                padding: 15px 25px;
            }
        }
    }

    &__img {
        width: 50%;
        display: flex;
        height: max-content;
        place-self: flex-end;
        padding: 12px 0 0 12px;
        background-color: rgba(240, 255, 240, 0.384);
        backdrop-filter: blur(8px);

        img {
            width: 100%;
        }
    }

    @include mq($until: xxl) {
        &__widget {
            width: 60%;
            form {
                input,
                button {
                    font-size: rem(18px);
                }

                & > div {
                    max-width: 230px;
                }
            }
        }

        &__img {
            width: 40%;
        }
    }

    @include mq($until: xl) {
        &__widget {
            form {
                & > div {
                    max-width: 220px;
                }

                button {
                    min-width: 170px;
                }
            }
        }
    }

    @include mq($until: lg) {
        height: 20vh;
        &__widget {
            margin-top: -5vh;
            width: 100%;
            form {
                input,
                button {
                    font-size: rem(18px);
                }

                & > div {
                    max-width: 230px;
                }
            }
        }

        &__img {
            display: none;
            width: 0;
        }
    }

    @include mq($until: md) {
        &__widget {
            form {
                input {
                    width: 70%;
                }
            }
        }
    }

    @include mq($until: xs) {
        height: 35vh;

        &__widget {
            form {
                align-content: center;
                flex-wrap: wrap;
                & > div {
                    width: 50%;
                    max-width: unset;
                    &:last-of-type {
                        width: 100%;
                        margin-top: 20px;
                        margin-right: 30px;
                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.house {
    display: flex;
    margin-top: $margin-xxl;
    margin-bottom: $margin-xxl;
    justify-content: space-between;

    h3 {
        margin: 15px 0;
        display: inline-block;
    }

    &__column {
        &_left,
        &_right {
            width: 25%;
        }

        &_left {
            text-align: right;
        }

        &_right {
            margin-top: 100px;
        }

        &_center {
            width: 50%;
            text-align: center;
            align-self: center;
            margin-top: $margin-xxl/2;
        }
    }

    &__img {
        background-color: $color-bg;

        &_shift {
            margin-top: $margin-xxl;
            position: relative;
            right: -6vw;
            background: linear-gradient(to left, $color-bg 90%, #ffffff00 10%);
        }

        &_right-bg {
        }
    }

    &__heading {
        h1 {
            margin-bottom: 50px;
        }

        margin-bottom: $margin-xxl/2;

        a {
            font-size: rem(60px);
        }
    }

    &__paragraph {
        ul {
            text-align: left;
            margin-left: 30%;
        }
    }

    p {
        width: 55%;
        text-align: left;
        margin-left: 30%;
    }

    @include mq($until: xxl) {
        &__heading {
            h1 {
                font-size: 3vw;
            }
        }
    }

    @include mq($until: xl) {
        h3 {
            font-size: rem(30px);
        }
    }

    @include mq($until: lg) {
        margin-top: 45vw;
        margin-bottom: $margin-xl;

        &__img {
            &_shift {
                margin-top: 0;
            }
        }

        &__heading {
            a {
                font-size: 3.5vw;
            }
            h1 {
                margin-bottom: 3vw;
            }
        }

        &__column {
            &_left,
            &_right {
                width: 45%;
            }

            &_center {
                width: 100%;
                height: 140vw;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: absolute;
                margin-top: 0;
            }

            &_left {
                height: 90vw;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }

        &__paragraph {
            display: none;
        }

        h3 {
            font-size: 4vw;
        }
    }

    @include mq($until: sm) {
        &__heading {
            h1 {
                font-size: 4vw;
            }

            a {
                font-size: 4.9vw;
            }
        }

        &__column {
            &_center {
                height: 143vw;
            }
        }
    }

    @include mq($until: xs) {
        flex-direction: column;
        position: relative;

        &__column {
            &_center {
                top: -40vw;
            }
        }

        &__column {
            &_right {
                align-self: self-end;
                margin-top: 12vw;
            }

            &_left {
                height: 172vw;
            }

            &_right,
            &_left {
                width: 100%;
            }
        }

        &__img {
            &_shift {
                right: 0;
            }
        }

        &__heading {
            a {
                font-size: 7.5vw;
            }

            h1 {
                font-size: 6vw;
            }
        }

        h3 {
            font-size: 6vw;
        }
    }
}

.margin-negative {
    &-top {
        margin-top: -60px;
    }

    &-bottom {
        margin-bottom: -60px;
    }

    &-left {
        margin-left: -40px;
    }

    &-right {
        margin-right: -40px;
    }
}

.about {
    margin-top: $margin-xxl;

    h2 {
        text-align: center;
        margin-bottom: $margin-sm;
    }

    &__img {
        width: 50%;
        position: relative;
        padding-right: 1.5vw;
        margin-right: 1.5vw;
        padding-bottom: 1vw;
        height: 33vw;
        img {
            width: 100%;
            object-fit: cover;
        }

        &-bg {
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: $color-bg;
            width: 85%;
            height: 40%;
            z-index: -1;
        }

        h2 {
            margin: 15px 0;
            text-align: right;
            font-size: 2.5vw;
        }
    }

    &__text {
        width: 49%;
        font-style: italic;
        padding-left: 1.5vw;
        align-self: center;
    }

    @include mq($until: xl) {
        margin-top: $margin-lg;
    }

    @include mq($until: lg) {
        &__img {
            height: 35.5vw;
        }
    }

    @include mq($until: md) {
        &__text {
            width: 103%;
        }

        .d-flex {
            flex-direction: column;
        }

        h2 {
            margin-bottom: 35px;
        }

        &__img {
            height: auto;
            width: 100%;
            margin-bottom: 40px;
            padding-right: 3.5vw;
            h2 {
                font-size: 4.5vw;
                margin: 15px 0 10px 0;
            }
        }
    }

    @include mq($until: md) {
        &__img {
            h2 {
                font-size: 6.5vw;
            }
        }
    }
}

.icons {
    margin-top: $margin-xxl;

    h2 {
        margin-bottom: 75px;
        text-align: center;
    }

    @include mq($until: xl) {
        margin-top: $margin-lg;
    }

    @include mq($until: md) {
        margin-bottom: 35px;
    }
}
