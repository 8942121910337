.subsite {
  margin-bottom: 10px;
  // padding-top: 2vw;
  &__header {
    width: 100%;
    height: 40vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;

    @include mq($until: md) {
      height: 55vw;
    }
    @include mq($until: sm) {
      height: 70vw;
    }

    div {
      // background-color: rgba(255,255,255, .8);
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      align-content: center;

      h1 {
        margin: 0;
        color: #fff;
      }
    }
  }
}
