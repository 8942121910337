.cards {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  .hidden {
    opacity: 0;
  }

  &__item-img {
    height: 35vw;
    position: relative;
    background-color: rgba(255, 255, 255, 0.89);
    backdrop-filter: blur(15px);
    li {
      transition: all 0.4s;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  ul {
    margin: 0;
  }

  &__item-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 950px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    height: 30vw;

    li {
      margin: 1.3vw 0;
      cursor: pointer;
      font-size: rem(25px);
      color: rgb(226, 226, 226);
      font-style: italic;
      margin-top: 0;
      transition: all 0.3s;
      width: fit-content;

      &:after {
        content: "";
        width: 0;
        height: 1px;
        position: absolute;
        background-color: rgb(209, 209, 209);
        left: 0;
        bottom: -6px;
        transition: all 0.3s;
      }

      &:hover {
        color: white;
        &:after {
          width: 6vw;
        }
      }
      &.active {
        &:after {
          width: 6vw;
        }
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;

    a {
      display: block;
      background-color: $color-bg;
      padding: 4vw 3vw;
      font-size: 2.5vw;
      position: relative;
      transition: all 0.4s;
      border: solid 1px $color-bg;

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        background-color: white;
        top: 50%;
        left: -110%;
        mix-blend-mode: difference;
        transition: all 0.2s;
      }
      &:hover {
        border: solid 1px black;

        &:before {
          left: -105%;
        }
      }
    }
  }

  &__container {
    &_right {
      width: 75vw;
    }

    &_left {
      width: 75vw;
      margin-right: -15vw;
    }
  }

  @include mq($until: xxl) {
    &__container {
      &_right {
        width: 65vw;
      }
    }

    &__item-title {
      li {
        margin: 1vw 0;
        cursor: pointer;
        font-size: rem(23px);
      }
    }
  }

  @include mq($until: xl) {
    &__container {
      &_right {
        width: 60vw;
      }
    }

    &__item-title {
      li {
        line-height: 1.2rem;
        font-size: rem(22px);
      }
    }
  }

  @include mq($until: lg) {
    &__container {
      &_right {
        width: 55vw;
      }
    }

    &__item-title {
      li {
        line-height: 1.2rem;
        font-size: rem(20px);
      }
    }
  }

  @include mq($until: md) {
    &__container {
      &_right {
        width: 50vw;
      }
    }

    &__item-title {
      @media (min-width: 768px) and (max-width: 1450px) {
        padding-bottom: 0px !important;
      }
      li {
        line-height: 1.1rem;
        font-size: rem(18px);
        @media (min-width: 768px) and (max-width: 1450px) {
          font-size: rem(18px);
        }
      }
    }

    &__button {
      a {
        &:before {
          width: 85%;
          left: -100%;
        }
      }
    }
  }
  
  @include mq($until: sm) {
    flex-direction: column;

    &__container_left {
      margin: 0;
      width: 100%;
    }

    &__container_right {
      width: 96%;
      margin-top: -3vh;
    }

    &__button a {
      height: 15vh;
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 5.5vw;
    }

    &__item-img {
      height: 35vh;
      min-height: 200px;
    }

    &__item-title {
      height: 50vh;
      min-height: 250px;
      padding-right: 15px;
      li {
        margin: 2vh 0;
      }
    }
  }
  &.inverted {
    align-items: flex-start;
    @include mq($until: sm) {
      flex-direction: column-reverse !important;
    }
    & .cards__container_left {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      margin-top: 6rem;
      @media (min-width: 768px) and (max-width: 1450px) {
        width: 65vw !important;
      }
      @include mq($until: sm) {
        flex-direction: column;
        margin-top: -3rem;
      }
    }
    & .cards__container_right {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      margin-top: 14rem;
      @include mq($until: sm) {
        margin-top: 0;
        flex-direction: column;
      }
      & h2 {
        margin-top: 3rem;
      }
    }
    @include mq($until: sm) {
      flex-direction: column;
    }
    & .cards__item-title {
      align-items: flex-end;
    }
    & h2 {
      display: flex;
      display: -webkit-flex;
      justify-content: flex-end;
      @media (min-width: 990px) and (max-width: 1100px) {
        font-size: 1.9rem;
        line-height: 1.125rem;
      }
    }
    & .cards__button {
      justify-content: flex-start;
      & a {
        &:before {
          content: "";
          width: 100%;
          height: 1px;
          position: absolute;
          background-color: white;
          top: 50%;
          left: 110%;
          mix-blend-mode: difference;
          transition: all 0.2s;
        }
      }
    }
  }
}
